import React from 'react';
import { Avatar, Box, Button, Dialog, DialogContent, Typography, PaletteColor } from '@mui/material';
import { theme } from '../../../theme';

interface ConfirmationDialogProps {
  open: boolean;
  icon: React.ReactElement;
  iconBgColor?: PaletteColor;
  iconColor?: PaletteColor;
  title: string;
  message: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirm: () => void;
  onCancel: () => void;
  confirmTextColor?: PaletteColor;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  icon,
  iconBgColor = theme.palette.error,
  iconColor = theme.palette.error,
  confirmTextColor = theme.palette.error,
  title,
  message,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
}) => (
  <Dialog
    open={open}
    onClose={onCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    sx={{
      '& .MuiDialog-container': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    }}
  >
    <DialogContent>
      <Box
        sx={{
          display: 'flex',
          pb: 2,
          pt: 3,
          px: 3,
        }}
      >
        <Avatar
          sx={{
            backgroundColor: () => iconBgColor[100],
            color: () => iconColor[700],
            mr: 4,
          }}
        >
          {icon}
        </Avatar>
        <div>
          <Typography>{title}</Typography>
          <Typography color="textSecondary"
            sx={{ mt: 1 }}
            variant="textSM">
            {message}
          </Typography>
        </div>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          px: 3,
          py: 1.5,
        }}
      >
        {
          cancelButtonText && <Button sx={{ mr: 2 }}
            variant="secondary"
            size="md"
            onClick={onCancel}>
            {cancelButtonText}
          </Button>
        }

        {
          confirmButtonText && <Button
            sx={{
              backgroundColor: confirmTextColor.main,
              '&:hover': {
                backgroundColor: confirmTextColor.dark,
              },
            }}
            variant={confirmTextColor === theme.palette.error ? 'bad' : 'primary'}
            size="md"
            onClick={onConfirm}
          >
            {confirmButtonText}
          </Button>
        }
      </Box>
    </DialogContent>
  </Dialog>
);
