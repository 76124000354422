/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { Button, Grid, TextField, Typography, useMediaQuery, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useAuthenticationService from '../../hooks/api/useAuthenticationService';
import { jwtDecode } from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/LogoOrderly.jpg';
import { ConfirmationDialog } from '../../components/shared/dialogs/ConfirmationDialog';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PaletteColor } from '../../theme/palette';

const ActivateAccount = () => {
  const { t } = useTranslation('authentication');
  const theme = useTheme();
  const { activateAccount } = useAuthenticationService();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [confirmModalBtnText, setConfirmModalBtnText] = useState('');
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalIcon, setModalIcon] = useState<any>(null);
  const [modalIconColor, setModalIconColor] = useState<PaletteColor>(theme.palette.success);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const accessToken = params.get('accessToken');

    if(!accessToken) {
      openErrorPopup();
    } else {
      const valid = isTokenValid(accessToken);
      if (!valid) {
        openErrorPopup();
      } else {
        setOpenModal(false);
      }
    }
  }, []);

  const isTokenValid = (token: string) => {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      return decoded.exp && decoded.exp > currentTime; // has token expired
    } catch (error) {
      return false;
    }
  };

  const openErrorPopup = () => {
    setModalMessage(t('activate.errorMessage'));
    setModalTitle(t('activate.errorTitle'));
    setConfirmModalBtnText('Proceed to login');
    setOpenModal(true);
    setModalIcon(<ErrorIcon />);
    setModalIconColor(theme.palette.error);
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
    setPasswordError('');
  };

  const handleConfirmPasswordChange = (e: any) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordError('');
  };

  const isFormValid = () => {
    let isValid = true;
    if (!password) {
      isValid = false;
      setPasswordError(t('activate.passwordRequired'));
      return;
    }

    if (password.length < 6) {
      isValid = false;
      setPasswordError(t('activate.passwordLengthInvalid'));
      return;
    }

    if (password !== confirmPassword) {
      isValid = false;
      setConfirmPasswordError(t('activate.passwordsMustMatch'));
      return;
    }

    return isValid;
  };

  const activateUser = () => {
    const params = new URLSearchParams(location.search);
    const accessToken = params.get('accessToken');

    if(accessToken) {
      activateAccount(password, accessToken)
        .then(() => {
          setOpenModal(true);
          setModalTitle(t('activate.successTitle'));
          setModalMessage(t('activate.successMessage'));
          setConfirmModalBtnText(t('activate.activationCompleteBtnText'));
          setModalIcon(<CheckCircleIcon />);
          setModalIconColor(theme.palette.success);
        })
        .catch((error) => {
          openErrorPopup();
        });
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (isFormValid()) {
      activateUser();
    }
  };

  const handleCancel = () => {
    navigate('/');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        marginTop: '3rem',
        padding: '1rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '360px',
          width: '100%',
        }}
      >
        <Box
          sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo}
            style={{ width: '200px', height: '50px' }}
            alt='' />
        </Box>
        <Typography variant="displaySM"
          sx={{ textAlign: 'start' }}>
          {t('activate.title')}
        </Typography>
        <Typography
          variant="textMD"
          sx={{ textAlign: 'center', fontWeight: 'normal', color: 'gray' }}
        >
          {t('activate.instructions').replace(/\s?<br>\s?/g, '\n')}
        </Typography>
        <Box sx={{ xs: 12, width: '100%' }}>
          <Typography
            variant="textSM"
            sx={{
              display: 'flex',
              alignItems: 'left',
              justifyContent: 'left',
              paddingTop: '20px',
              paddingBottom: '6px',
            }}
          >
            {t('password')}
          </Typography>
          <TextField
            value={password}
            onChange={handlePasswordChange}
            onKeyDown={handleKeyPress}
            type="password"
            placeholder={t('password')}
            variant="outlined"
            error={!!passwordError}
            helperText={passwordError}
            fullWidth
            required />
        </Box>
        <Box sx={{ xs: 12, width: '100%' }}>
          <Typography
            variant="textSM"
            sx={{
              display: 'flex',
              alignItems: 'left',
              justifyContent: 'left',
              paddingTop: '20px',
              paddingBottom: '6px',
            }}
          >
            {t('activate.confirmPassword')}
          </Typography>
          <TextField
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            onKeyDown={handleKeyPress}
            type="password"
            placeholder={t('activate.confirmPassword')}
            variant="outlined"
            error={!!confirmPasswordError}
            helperText={confirmPasswordError}
            fullWidth
            required />
        </Box>
        <Grid sx={{ paddingTop: '24px', width: '100%' }}>
          <Button
            type="submit"
            variant="primary"
            fullWidth
            onClick={handleSubmit}
            sx={{
              backgroundColor: isMobile
                ? theme.palette.common.black
                : 'success[800]',
              border: 'success[800]',
              padding: '16px 10px',
              '&:hover': {
                backgroundColor: isMobile
                  ? theme.palette.common.black
                  : 'success[800]',
              },
            }}
          >
            {t('activate.submit')}
          </Button>
        </Grid>
        <ConfirmationDialog
          icon={modalIcon}
          open={openModal}
          title={modalTitle}
          message={modalMessage}
          cancelButtonText={''}
          confirmButtonText={confirmModalBtnText}
          onConfirm={handleCancel}
          onCancel={handleCancel}
          confirmTextColor={theme.palette.primary}
          iconColor={modalIconColor}
          iconBgColor={modalIconColor}
        />
      </Box>
    </Box>
  );
};

export default ActivateAccount;
